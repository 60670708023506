import * as client_hooks from '../../../src/hooks/index.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112')
];

export const server_loads = [0,2,4,8,17,18,11,19,20,13,14,15,6,7];

export const dictionary = {
		"/(nav)": [~21,[2],[3]],
		"/(nav)/@[slug]": [22,[2,4],[3]],
		"/(nav)/@[slug]/posts": [23,[2,4],[3]],
		"/@[slug]/sessions": [~97],
		"/@[slug]/sessions/[slug]": [~98],
		"/(nav)/account": [~34,[2,8],[3]],
		"/(nav)/account/chat": [~35,[2,8],[3]],
		"/(nav)/account/chat/[id]": [~36,[2,8],[3]],
		"/(nav)/account/following": [~37,[2,8],[3]],
		"/(nav)/account/onboarding": [~38,[2,8],[3]],
		"/(nav)/account/sessions": [~41,[2,8],[3]],
		"/(nav)/account/sessions/create": [~42,[2,8],[3]],
		"/(nav)/account/session/[coachingId]": [~39,[2,8],[3]],
		"/(nav)/account/session/[coachingId]/[coachingSessionId]": [~40,[2,8],[3]],
		"/(nav)/account/settings": [43,[2,8,9],[3]],
		"/(nav)/account/settings/about": [~44,[2,8,9],[3]],
		"/(nav)/account/settings/achievements": [~45,[2,8,9],[3]],
		"/(nav)/account/settings/availability": [~46,[2,8,9],[3]],
		"/(nav)/account/settings/connected-accounts": [~47,[2,8,9],[3]],
		"/(nav)/account/settings/discounts": [~48,[2,8,9],[3]],
		"/(nav)/account/settings/faq": [~49,[2,8,9],[3]],
		"/(nav)/account/settings/groups": [~50,[2,8,9],[3]],
		"/(nav)/account/settings/notifications": [~51,[2,8,9],[3]],
		"/(nav)/account/settings/payments": [~52,[2,8,9],[3]],
		"/(nav)/account/settings/pro": [~53,[2,8,9],[3]],
		"/(nav)/account/settings/security": [~54,[2,8,9],[3]],
		"/(nav)/account/settings/sessions": [~55,[2,8,9],[3]],
		"/(nav)/account/settings/short-links": [~56,[2,8,9],[3]],
		"/(nav)/account/settings/subscriptions": [~57,[2,8,9],[3]],
		"/(nav)/account/settings/testimonials": [~58,[2,8,9],[3]],
		"/(nav)/account/studio": [~59,[2,8,10],[3]],
		"/(nav)/account/studio/courses": [~60,[2,8,10],[3]],
		"/(nav)/account/studio/customers": [~61,[2,8,10],[3]],
		"/(nav)/account/studio/customers/[id]": [~62,[2],[3]],
		"/(nav)/account/studio/discounts": [~63,[2,8,10],[3]],
		"/(nav)/account/studio/groups": [~64,[2,8,10],[3]],
		"/(nav)/account/studio/referrals": [~65,[2,8,10],[3]],
		"/(nav)/account/studio/sessions": [~66,[2,8,10],[3]],
		"/(nav)/account/studio/videos": [67,[2,8,10],[3]],
		"/(nav)/account/wallet": [~68,[2,8],[3]],
		"/auth/account/create": [99,[16]],
		"/auth/account/forgot-password": [100,[16]],
		"/auth/account/login": [101,[16]],
		"/auth/account/reset-password": [~102,[16]],
		"/auth/external/[...provider]": [103,[17]],
		"/checkout": [~104,[18]],
		"/checkout/reschedule": [~105,[18]],
		"/(nav)/courses": [~69,[2,11],[3]],
		"/(nav)/courses/create": [~71,[2,11],[3]],
		"/courses/create/[guideSlug]": [~106,[19]],
		"/courses/create/[guideSlug]/[chapterSlug]": [~107,[19]],
		"/(nav)/courses/mine": [~72,[2,11],[3]],
		"/courses/preview/[guideSlug]/[chapterSlug]": [~108,[19]],
		"/(nav)/courses/view/[guideSlug]": [~73,[2,11],[3]],
		"/(nav)/courses/view/[guideSlug]/[chapterSlug]": [~74,[2,11],[3]],
		"/(nav)/courses/[type]": [~70,[2,11],[3]],
		"/feedback/[...board]": [109,[20]],
		"/gamerzclass": [~110],
		"/(nav)/groups": [~75,[2],[3]],
		"/(nav)/groups/create": [87,[2],[3]],
		"/(nav)/groups/mine": [~88,[2],[3]],
		"/(nav)/groups/[filter=groupsFilter]": [~76,[2],[3]],
		"/(nav)/groups/[slug]": [~77,[2],[3]],
		"/(nav)/groups/[slug]/about": [~78,[2,12],[3]],
		"/(nav)/groups/[slug]/members": [~79,[2,12],[3]],
		"/(nav)/groups/[slug]/settings": [80,[2,13],[3]],
		"/(nav)/groups/[slug]/settings/channels": [81,[2,13],[3]],
		"/(nav)/groups/[slug]/settings/details": [82,[2,13],[3]],
		"/(nav)/groups/[slug]/settings/discord-roles": [83,[2,13],[3]],
		"/(nav)/groups/[slug]/settings/tiers": [84,[2,13],[3]],
		"/(nav)/groups/[slug]/settings/visibility": [85,[2,13],[3]],
		"/(nav)/groups/[slug]/tiers": [86,[2],[3]],
		"/(nav)/posts/[id]": [~89,[2],[3]],
		"/pro": [111],
		"/(nav)/rules": [~90,[2],[3]],
		"/(nav)/sessions": [~91,[2,14],[3]],
		"/(nav)/sessions/mine": [~93,[2,14],[3]],
		"/(nav)/sessions/[filter]": [~92,[2,14],[3]],
		"/(nav)/tags/[tag]/[...path]": [~94,[2,15],[3]],
		"/test": [112],
		"/(nav)/the-first-annual-metafy-monthly-contest": [~95,[2],[3]],
		"/(nav)/updates": [~96,[2],[3]],
		"/(nav)/[game]": [~24,[2,5],[3]],
		"/(nav)/[game]/courses": [~25,[2,5,6],[3]],
		"/(nav)/[game]/courses/mine": [~27,[2,5,6],[3]],
		"/(nav)/[game]/courses/[type]": [~26,[2,5,6],[3]],
		"/(nav)/[game]/groups": [~28,[2,5],[3]],
		"/(nav)/[game]/groups/mine": [~30,[2,5],[3]],
		"/(nav)/[game]/groups/[filter=groupsFilter]": [~29,[2,5],[3]],
		"/(nav)/[game]/sessions": [~31,[2,5,7],[3]],
		"/(nav)/[game]/sessions/mine": [~33,[2,5,7],[3]],
		"/(nav)/[game]/sessions/[filter]": [~32,[2,5,7],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';